import { Auth } from "aws-amplify";
import axios from "axios";
import * as _ from "lodash";

import { LOCALSTORAGE_ACCESS_TOKEN, LOCALSTORAGE_USER_INFO } from "@Utils/constants";

export const login = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password);

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      // console.log(await Auth.completeNewPassword(user, "1q2w3e4r"));
      return {
        status: false,
        message: "You need to change your password for first time logging in",
        data: user,
      };
    } else if (_.has(user, "username")) {
      const userInfo = await axios.get(`${process.env.VUE_APP_API_END_POINT}/get-me`, {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      });

      const { fullName, email, id, accessLevel } = userInfo.data.data;

      setAuth(user.signInUserSession.idToken.jwtToken, {
        username: fullName,
        email,
        id,
        role: accessLevel,
      });

      return {
        status: true,
        message: "Login successful!",
        data: userInfo.data.data,
      };
    } else {
      return {
        status: false,
        message: user.message,
      };
    }
  } catch (error) {
    console.error("error signing in", error);
    return {
      status: false,
      message: error.message,
    };
  }
};

export const resetPassword = async (resetPasswordData, newPassword) => {
  try {
    const user = await Auth.completeNewPassword(resetPasswordData, newPassword);

    if (_.has(user, "username")) {
      const userInfo = await axios.get(`${process.env.VUE_APP_API_END_POINT}/get-me`, {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      });

      const { fullName, email, id, accessLevel } = userInfo.data.data;

      setAuth(user.signInUserSession.idToken.jwtToken, {
        username: fullName,
        email,
        id,
        role: accessLevel,
      });

      return {
        status: true,
        message: "Login successful!",
        data: userInfo.data.data,
      };
    } else {
      return {
        status: false,
        message: user.message,
      };
    }
  } catch (error) {
    console.error("error signing in", error);
    return {
      status: false,
      message: error.message,
    };
  }
};

export const logout = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error("error signing out: ", error);
  } finally {
    clearAuth();
  }
};

export const setAuth = (token, user) => {
  localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, JSON.stringify(token));
  localStorage.setItem(LOCALSTORAGE_USER_INFO, JSON.stringify(user));
};

export const clearAuth = () => {
  localStorage.removeItem(LOCALSTORAGE_ACCESS_TOKEN);
  localStorage.removeItem(LOCALSTORAGE_USER_INFO);
};

export const getAccessToken = async () => {
  try {
    const respAccess = await Auth.currentSession();
    return respAccess.idToken.jwtToken;
  } catch (error) {
    console.log({ error });
  }
  return JSON.parse(localStorage.getItem(LOCALSTORAGE_ACCESS_TOKEN));
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem(LOCALSTORAGE_USER_INFO));
};

export const isLoggedIn = () => {
  let user = getUserInfo();
  return !!user;
};
