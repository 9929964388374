<template>
  <section class="event-detail">
    <h3 v-if="empty(data.eventDetail)" class="text-center text-magenta">
      <template v-if="data.invalidEvent"> Event invalid </template>
    </h3>
    <template v-else>
      <div class="block md:flex justify-between">
        <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">
          {{ data.eventDetail.name }}
        </h1>
      </div>

      <div class="flex flex-wrap mt-5 lg:mt-3">
        <div class="w-full">
          <ul class="flex flex-row list-none md:flex-wrap border-b-3 border-grey-light overflow-x-scroll overflow-y-hidden md:overflow-visible">
            <li class="-mb-3px pr-2" v-for="(item, index) in data.dataSource" :key="index">
              <a
                :class="[data.openTabNumber === index ? activeClass : inactiveClass]"
                class="text-base font-bold uppercase block leading-normal cursor-pointer p-1.5 md:px-2 lg:px-6 md:pb-4 pl-0"
                @click="toggleTabs(index)"
              >
                {{ item.title }}
              </a>
            </li>
          </ul>

          <div class="relative flex flex-col min-w-0 break-words w-full">
            <div class="flex-auto dark:text-dark-textPrimary pt-4">
              <div v-for="(item, index) in data.dataSource" :key="index" :class="[data.openTabNumber !== index ? 'hidden' : 'block']">
                <component :is="item.component" :isActive="data.openTabNumber === index" :eventDetail="data.eventDetail" @onUpdate="onUpdateEvent" @toggleLoading="onToggleLoading($event)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { ref, reactive, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralTab from "@Components/pages/event-detail/GeneralTab";
import PrepareTab from "@Components/pages/event-detail/PrepareTab";
import DeceasedInfo from "@Components/pages/event-detail/DeceasedInfo";
import QA from "@Components/pages/event-detail/QA";
import { filter, find } from "lodash";
import { empty } from "@/utils";
import { useTableData } from "@Libs/hooks/useDataTable";
import EventSubcription from "@/graphql/subscription/Event";
import { useSubscription } from "@vue/apollo-composable";
import useEmitter from "@Helpers/emitter";

export default {
  name: "EventDetail",
  components: {
    GeneralTab,
    PrepareTab,
    DeceasedInfo,
    QA,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const mitter = useEmitter();
    const data = reactive({
      openTabNumber: 0,
      dataSource: [
        {
          title: "General",
          component: "GeneralTab",
        },
        {
          title: "Deceased Info",
          component: "DeceasedInfo",
        },
        {
          title: "Question & Answer",
          component: "QA",
        },
        {
          title: "Pre/posts views",
          component: "PrepareTab",
        },
      ],
      eventDetail: {},
      invalidEvent: false,
      isUpdator: false,
    });
    data.dataSource = filter(data.dataSource, item => item !== false);

    const activeClass = ref("text-blue-light dark:text-dark-textAccent border-b-3 border-blue-light dark:border-dark-textAccent");
    const inactiveClass = ref("text-grey-mid");
    const doGetEvent = async (isForce = true) => {
      try {
        if (isForce) {
          store.dispatch("toggleLoading");
        }
        data.invalidEvent = false;
        const { fetchEventsListDataTable } = useTableData();
        const eventList = await fetchEventsListDataTable("", "asc");
        data.eventDetail = find(eventList, item => item.id === route.params.eventId);

        data.invalidEvent = empty(data.eventDetail);
      } catch (error) {
        console.log({ error });
        data.invalidEvent = true;
      } finally {
        if (isForce) {
          store.dispatch("closeLoading");
        }
      }
    };
    const { onResult } = useSubscription(EventSubcription, () => ({
      id: route.params.eventId,
    }));
    onResult(({ data: { onEventUpdate: event } }) => {
      if (!data.isUpdator) {
        store.dispatch("toggleConfirmDialog", {
          eventListen: "trigger_event_updated",
          isConfirmDialog: true,
          title: `Event <b>${event.id}</b> updated`,
          message: `${event.message}`,
          confirmButtonText: "Re-load",
          confirmAction: {
            type: "TRIGGER_EVENT_UPDATED",
          },
        });
      }
    });

    mitter.all.delete("trigger_event_updated");
    mitter.on("trigger_event_updated", async () => {
      const { type } = store.state.auth.confirmDialog.confirmAction;
      if (type === "TRIGGER_EVENT_UPDATED") {
        store.dispatch("resetConfirmDialog");
        await doGetEvent();
        data.isUpdator = false;
      }
    });
    onBeforeMount(doGetEvent);

    function toggleTabs(tabNumber) {
      data.openTabNumber = tabNumber;
    }

    function onUpdateEvent() {
      data.isUpdator = true;
      doGetEvent(false);
    }

    function onToggleLoading(isLoading = true) {
      if (isLoading) {
        store.dispatch("toggleLoading");
      } else {
        store.dispatch("closeLoading");
      }
    }

    return {
      data,
      activeClass,
      inactiveClass,
      toggleTabs,
      doGetEvent,
      empty,
      onUpdateEvent,
      onToggleLoading,
    };
  },
};
</script>
