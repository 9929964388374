export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function dynamicFilter(arr, property, searchText) {
  if (!searchText.length) {
    return arr;
  } else {
    return arr.filter(item =>
      item[property].toLowerCase().includes(searchText.toLowerCase()),
    );
  }
}

export function dynamicPaginate(data, currentPage, perPage) {
  const from = currentPage * perPage - perPage;
  const to = currentPage * perPage;
  return data.slice(from, to);
}
