<template>
  <div
    class="flex justify-between items-center"
    @click="toggleActive = !toggleActive"
  >
    <div
      class="
        w-12
        h-8
        md:w-16
        md:h-10
        flex
        items-center
        bg-gray-300
        rounded-full
        p-1
        duration-300
        ease-in-out
        border border-grey-mid
      "
      :class="{ 'bg-magenta': toggleActive }"
    >
      <div
        class="
          w-6
          h-6
          md:w-8
          md:h-8
          rounded-full
          shadow-md
          transform
          duration-300
          ease-in-out
        "
        :class="[
          toggleActive && 'translate-x-4 md:translate-x-6',
          toggleActive ? 'bg-white' : 'bg-magenta',
        ]"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      toggleActive: false,
    };
  },
  watch: {
    toggleActive(modelValue) {
      this.$emit("update:modelValue", modelValue);
    },
  },
  mounted() {
    this.toggleActive = this.modelValue;
    setTimeout(() => {
      this.toggleActive = this.modelValue;
    }, 300);
  },
};
</script>
