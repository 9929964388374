<template>
  <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5" @submit="onSubmit">
    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Deceased Full Name: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.deceasedFullName"
          name="deceasedFullName"
          type="text"
          :class="{
            'text-danger border border-danger': errors['deceasedFullName'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["deceasedFullName"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-full mt-2 md:mt-0 md:w-40 lg:w-1/4 md:pt-0">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> deceased Person Image: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
        <label
          class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
        >
          <template v-if="empty(eventImage.deceasedPersonImage)">
            <XLogo class="h-8 w-8 text-blue-light absolute inset-y-1/4 left-5 lg:left-1/8" />
            <span class="font-bold absolute top-1/5 left-14 lg:left-1/4">
              <p>Image</p>
            </span>
          </template>
          <img v-else :src="eventImage.deceasedPersonImage" alt="Home Logo" class="h-full" />
          <input name="deceasedPersonImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
        </label>

        <div class="flex" v-if="!empty(eventImage.deceasedPersonImage)">
          <button
            type="button"
            class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
            @click="handleDeleteFilesUpload('deceasedPersonImage')"
          >
            <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-full mt-2 md:mt-0 md:w-40 lg:w-1/4 md:pt-0">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Deceased Galleries: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
        <label
          class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
          v-if="empty(eventImage.deceasedGallery)"
        >
          <XLogo class="h-8 w-8 text-magenta absolute inset-y-1/4 left-5 lg:left-1/8" />
          <span class="font-bold absolute top-1/5 left-14 lg:left-1/4">
            <p>Photo</p>
          </span>
          <input name="deceasedGallery" type="file" class="hidden" @change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
        </label>
        <div class="flex flex-wrap" v-else>
          <label v-for="(image, index) in eventImage.deceasedGallery" :key="index" class="relative mt-6 self-start cursor-pointer mr-10">
            <div class="image-wrapper">
              <img :src="image" alt="Deceased Gallery" class="h-20 lg:h-24" />
            </div>
            <DeleteIcon class="w-6 h-6 absolute top-0 -right-6" @click.prevent="handleDeleteFilesUpload('deceasedGallery', index)" />
            <input name="deceasedGallery" type="file" class="hidden" @change="e => handleChangeFilesUpload(e, index)" accept="image/png, image/jpeg" />
          </label>
        </div>

        <label v-if="!empty(eventImage.deceasedGallery)" class="w-40 lg:w-60 h-20 lg:h-24 flex flex-col relative items-start cursor-pointer">
          <div class="w-40 h-full absolute flex items-center text-lg lg:text-xl text-magenta font-bold">
            <AddIcon class="h-6 w-6 mr-1 lg:mr-2" />
            <span class="font-bold"> Add Another </span>
          </div>
          <input name="deceasedGallery" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
        </label>
        <!-- </div> -->
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Birth Date: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="dateTime.birthDate"
          name="birthDate"
          type="date"
          :class="{
            'text-danger border border-danger': errors['birthDate'],
          }"
          class="w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary text-center md:text-left border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3 mr-4 md:mr-12"
        />
        <p class="text-danger dark:text-danger text-base italic mr-1 md:mr-14 lg:mr-28">
          {{ errors["birthDate"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Death Date: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="dateTime.deathDate"
          name="deathDate"
          type="date"
          :class="{
            'text-danger border border-danger': errors['deathDate'],
          }"
          class="w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary text-center md:text-left border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3 mr-4 md:mr-12"
        />
        <p class="text-danger dark:text-danger text-base italic mr-1 md:mr-14 lg:mr-28">
          {{ errors["deathDate"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Obituary Text: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.ObituaryText"
          name="ObituaryText"
          as="textarea"
          rows="4"
          :class="{
            'text-danger border border-danger': errors['ObituaryText'],
          }"
          class="w-full text-xs md:text-base text-black-1 dark:text-dark-textPrimary bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded p-2 md:p-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["ObituaryText"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> message From Family: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.messageFromFamily"
          name="messageFromFamily"
          as="textarea"
          rows="4"
          :class="{
            'text-danger border border-danger': errors['messageFromFamily'],
          }"
          class="w-full text-xs md:text-base text-black-1 dark:text-dark-textPrimary bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded p-2 md:p-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["messageFromFamily"] }}
        </p>
      </div>
    </div>

    <button type="submit" class="button_large-Alternative absolute right-0 -bottom-4 md:-top-24 md:bottom-auto uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none">
      Save
    </button>
  </Form>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

import { getMomentT, formatDate, formatDateTime } from "@Helpers/date";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from "moment";
import { forOwn, isString, cloneDeep, keys, startCase, camelCase, get } from "lodash";
import axios from "axios";

import { sleep } from "@Helpers/sleep";
import { putEvent, getS3UploadKey } from "@API/event/event-repository";
import { empty, isFile } from "@/utils";
import XLogo from "@Assets/images/XLogo";
import DeleteIcon from "@Assets/images/DeleteIcon";
import AddIcon from "@Assets/images/AddIcon";

export default {
  name: "GeneralTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    XLogo,
    DeleteIcon,
    AddIcon,
  },
  emits: ["toggleLoading", "onUpdate"],
  setup(props) {
    const currentEvent = computed(() => props.eventDetail);

    const dateTime = computed(() => ({
      birthDate: formatDate(currentEvent.value.birthDate, "yyyy-MM-DD"),
      deathDate: formatDate(currentEvent.value.deathDate, "yyyy-MM-DD"),
    }));

    const schema = Yup.object().shape({});

    return {
      currentEvent,
      dateTime,
      schema,
    };
  },

  data() {
    return {
      filesUpload: {
        deceasedPersonImage: null,
        deceasedGallery: [],
      },
      eventImage: {},
      payload: {},
    };
  },
  computed: {
    theme() {
      return this.$store.getters.getTheme;
    },
  },
  watch: {
    currentEvent: {
      handler(event) {
        this.eventImage = {
          deceasedPersonImage: event.deceasedPersonImage,
          deceasedGallery: empty(event.deceasedGallery) ? [] : [...event.deceasedGallery],
        };
        this.payload = {
          deceasedPersonImage: event.deceasedPersonImage,
          deceasedGallery: empty(event.deceasedGallery) ? [] : [...event.deceasedGallery],
        };
      },
      immediate: true,
    },
  },
  methods: {
    empty,
    handleDeleteFilesUpload(property, index) {
      let logos = "";
      if (property === "deceasedGallery") {
        logos = this.eventImage.deceasedGallery;
        logos.splice(index, 1);
        this.payload.deceasedGallery.splice(index, 1);
        this.filesUpload.deceasedGallery.splice(index, 1);
      } else {
        this.filesUpload = {
          ...this.filesUpload,
          [property]: null,
        };
        this.payload = {
          ...this.payload,
          [property]: logos,
        };
      }
      this.eventImage = {
        ...this.eventImage,
        [property]: logos,
      };
    },
    handleChangeFilesUpload(e, index) {
      const fileSelected = e.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        if (e.target.name !== "deceasedGallery") {
          this.filesUpload[e.target.name] = fileSelected;
          this.eventImage = {
            ...this.eventImage,
            [e.target.name]: event.target.result,
          };
        } else {
          const logos = this.eventImage.deceasedGallery || [];
          if (index > -1) {
            logos[index] = event.target.result;
            this.filesUpload[e.target.name][index] = fileSelected;
            this.payload.deceasedGallery.splice(index, 1);
          } else {
            this.filesUpload[e.target.name].push(fileSelected);
            logos.push(event.target.result);
          }
          this.eventImage = {
            ...this.eventImage,
            [e.target.name]: logos,
          };
        }
      };
      reader.readAsDataURL(fileSelected);
    },
    async doUpdateFile(file, property, progress) {
      const currentProgress = empty(progress) ? "" : progress;
      if (!file.type.includes("image/")) return;

      const s3Result = await getS3UploadKey(file.type.replace("image/", ""));

      const {
        data: { fields, url },
      } = s3Result;

      const formData = new FormData();

      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", file);

      const result = await axios.post(url, formData, {});

      if (result.status === 204) {
        if (property === "deceasedGallery") {
          this.payload[property] = this.payload[property] || [];
          this.payload[property].push(fields.key);
        } else {
          this.payload[property] = fields.key;
        }
      }

      // Show Alert
      // Show Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: true,
        status: result.status === 204,
        title: `[${result.status === 204 ? "Successfully" : "Failed"}] ${startCase(camelCase(property))}${currentProgress}`,
        message: `${result.status === 204 ? "Upload file successfully!" : "Upload file failed!"}`,
      });

      await sleep(1600);

      // Hide Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: false,
      });
    },
    async handleUploadFiles(property) {
      // this.$emit("toggleLoading");
      if (property === "deceasedGallery") {
        const fileUploads = this.filesUpload[property];
        for (let i = 0; i < fileUploads.length; i++) {
          await this.doUpdateFile(fileUploads[i], property, ` ${i + 1}/${fileUploads.length}`);
        }
      } else {
        await this.doUpdateFile(this.filesUpload[property], property);
      }
      // this.$emit("toggleLoading");
    },
    async onSubmit() {
      try {
        this.$emit("toggleLoading");
        const payload = {
          ...this.currentEvent,
          birthDate: getMomentT(this.dateTime.birthDate).format(),
          deathDate: getMomentT(this.dateTime.deathDate).format(),
        };
        forOwn(payload, (value, key) => {
          if (value === " " || value?.url === " ") delete payload[key];
          // if (key.includes("Url") && (!value || !value.url.length || value.url === " ")) delete payload[key];
        });
        const filesUploadKeys = keys(this.filesUpload);

        for (let i = 0; i < filesUploadKeys.length; i++) {
          const file = this.filesUpload[filesUploadKeys[i]];
          if (isFile(file) || !empty(file)) {
            await this.handleUploadFiles(filesUploadKeys[i]);
          }
        }
        const result = await putEvent({
          ...payload,
          ...this.payload,
        });
        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Successfully!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>

<style >
.dp__input_icon {
  left: initial;
  right: 20px;
}
.dp__input_icon_pad {
  padding-left: 10px;
}
.dp__input {
  background-color: transparent;
  border: none;
}
</style>
