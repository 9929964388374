<template>
  <section
    class="
      h-page-sm
      md:h-page
      overflow-y-auto
      bg-white
      dark:bg-dark-bgSecondary
      rounded-xl
      shadow-2xl
      p-4
      md:p-5
      lg:p-10
    "
  >
    <div class="block md:flex justify-between">
      <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">
        Reset Passowrd
      </h1>
    </div>

    <div class="mt-5 lg:mt-10">
      <div
        class="
          bg-white
          dark:bg-dark-bgSecondary
          border-t-2 border-grey-light
          dark:border-dark-textSecondary
        "
      >
        <div class="relative flex flex-col min-w-0 break-words w-full">
          <div class="flex-auto dark:text-dark-textPrimary pt-6">
            <VeeForm
              v-slot="{ errors, handleSubmit }"
              :validation-schema="schema"
              as="div"
              class="flex flex-col font-fs-elliot-pro py-5"
            >
              <form @submit="handleSubmit($event, onSubmit)" ref="form">
                <div class="flex flex-col md:flex-row mb-7 lg:mb-8">
                  <div class="w-full md:w-1/3 lg:w-1/6 mb-2 lg:mb-0">
                    <label
                      for="user-email"
                      class="
                        block
                        capitalize
                        tracking-wide
                        text-grey-mid text-xs
                        md:text-lg
                        font-semibold
                        pt-2.5
                      "
                    >
                      Email:
                    </label>
                  </div>
                  <div class="w-full md:w-2/3">
                    <Field
                      id="user-email"
                      v-model="payload.email"
                      name="email"
                      type="text"
                      :class="{
                        'text-danger border border-danger': errors.email,
                      }"
                      class="
                        block
                        w-full
                        appearance-none
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-xs
                        md:text-lg
                        text-black-1
                        dark:text-dark-textPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        py-2
                        md:py-3
                        px-2
                        md:px-4
                        mb-3
                      "
                    />
                    <p
                      class="
                        text-danger
                        dark:text-danger
                        text-xs
                        md:text-base
                        italic
                      "
                    >
                      {{ errors.email }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row mb-7 lg:mb-8">
                  <div class="w-full md:w-1/3 lg:w-1/6 mb-2 lg:mb-0">
                    <label
                      for="password"
                      class="
                        block
                        capitalize
                        tracking-wide
                        text-grey-mid text-xs
                        md:text-lg
                        font-semibold
                        pt-2.5
                      "
                    >
                      New Password:
                    </label>
                  </div>
                  <div class="w-full md:w-2/3">
                    <Field
                      id="password"
                      v-model="payload.password"
                      name="password"
                      type="password"
                      :class="{
                        'text-danger border border-danger': errors.password,
                      }"
                      class="
                        block
                        w-full
                        appearance-none
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-xs
                        md:text-lg
                        text-black-1
                        dark:text-dark-textPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        py-2
                        md:py-3
                        px-2
                        md:px-4
                        mb-3
                      "
                    />
                    <p
                      class="
                        text-danger
                        dark:text-danger
                        text-xs
                        md:text-base
                        italic
                      "
                    >
                      {{ errors.password }}
                    </p>
                  </div>
                </div>
                <p
                  v-if="!empty(errorMessage)"
                  class="text-danger my-2"
                  v-html="errorMessage"
                />
                <button
                  class="
                    button_large-Alternative
                    uppercase
                    bg-magenta
                    border
                    rounded-full
                    py-3
                    lg:p-4
                    focus:outline-none
                  "
                >
                  Submit
                </button>
              </form>
            </VeeForm>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Form as VeeForm, Field } from "vee-validate";
import * as Yup from "yup";

import { sleep } from "@Helpers/sleep";
import { postUser } from "@API/user/user-repository";
import { map, filter } from "lodash";

import { resetPassword } from "@API/user/user-repository";
import { empty } from "@/utils";
export default {
  name: "ResetPassword",
  components: {
    VeeForm,
    Field,
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().required("User email is required"),
      password: Yup.string().required("Password is required"),
    });

    return {
      schema,
    };
  },
  data() {
    return {
      payload: {},
      errorMessage: "",
    };
  },
  methods: {
    empty,
    async onSubmit() {
      try {
        this.$store.dispatch("toggleLoading");
        this.errorMessage = "";
        const result = await resetPassword(this.payload);
        this.$refs.form.reset();

        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Successfully!" : "Failed!",
          message: result.message,
        });
        if (!result.success) {
          this.errorMessage = result.message;
        } else {
          this.$refs.form.reset();
        }
      } catch (error) {
        console.log({ error });
        this.errorMessage = "Reset password error!";
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: false,
          title: "Error!",
          message: this.errorMessage,
        });
      } finally {
        this.$store.dispatch("closeLoading");

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
