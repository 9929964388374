<template>
  <div v-if="isShow" class="flex fixed top-0 left-0 justify-center w-full h-full z-50">
    <div class="flex min-h-screen min-w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-grey-dark opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        class="inline-block align-bottom bg-white dark:bg-dark-bgSecondary rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-xl"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="pb-4">
          <div class="flex flex-col">
            <div class="relative bg-magenta py-2">
              <span class="absolute right-2 top-2 cursor-pointer text-black-1" @click="$emit('onClose')">
                <CloseIcon class="h-5 w-5 md:h-7 md:w-7" />
              </span>
              <h3 class="text-lg leading-6 font-medium text-white ml-3" id="modal-headline">
                {{ isNew ? "Create new question" : `Edit "${questionDetail.question}"` }}
              </h3>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left text-gray-900 dark:text-dark-textPrimary">
              <div class="mt-2 sm:w-full">
                <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5" @submit="onSubmit">
                  <div class="flex flex-col">
                    <div class="w-full">
                      <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Question </label>
                    </div>

                    <div class="w-full mt-2">
                      <Field
                        v-model="data.question"
                        name="question"
                        type="text"
                        :class="{
                          'text-danger border border-danger': errors['question'],
                        }"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                      <p class="text-danger dark:text-danger text-base italic">
                        {{ errors["question"] }}
                      </p>
                    </div>
                  </div>

                  <div class="flex flex-col">
                    <div class="w-full">
                      <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Answer </label>
                    </div>

                    <div class="w-full mt-2">
                      <Field
                        v-model="data.answer"
                        name="answer"
                        as="textarea"
                        rows="4"
                        :class="{
                          'text-danger border border-danger': errors['answer'],
                        }"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                      <p class="text-danger dark:text-danger text-base italic">
                        {{ errors["answer"] }}
                      </p>
                    </div>
                  </div>
                  <div class="flex justify-between">
                    <button class="button_large uppercase bg-white text-magenta border rounded-full py-2 w-full mr-2 focus:outline-none" @click.stop.prevent="onCancel">Cancel</button>
                    <button type="submit" class="button_large uppercase bg-magenta border rounded-full py-2 w-full focus:outline-none">OK</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { empty } from "@/utils";
import CloseIcon from "@Assets/images/CloseIcon";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { reactive, watch, computed } from "vue";
export default {
  name: "ConfirmDialog",
  components: {
    CloseIcon,
    Form,
    Field,
  },
  props: {
    questionDetail: {
      type: Object,
      requried: true,
    },
    isNew: {
      type: Boolean,
      requried: false,
    },
  },
  setup(props, { emit }) {
    const isShow = computed(() => !empty(props.questionDetail));
    const schema = Yup.object().shape({
      question: Yup.string().required("Question is required"),
      answer: Yup.string().required("Answer is required"),
    });
    const data = reactive({
      question: props.questionDetail.question,
      answer: props.questionDetail.answer,
    });
    watch(isShow, value => {
      if (value) {
        data.question = props.questionDetail.question;
        data.answer = props.questionDetail.answer;
      }
    });
    const onSubmit = () => {
      emit("onUpdate", {
        ...props.questionDetail,
        ...data,
      });
    };
    const onCancel = () => {
      emit("onClose");
    };
    return {
      data,
      schema,
      onSubmit,
      isShow,
      onCancel,
    };
  },
};
</script>
