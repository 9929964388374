<template>
  <div>
    <table class="text-left w-full">
      <thead>
        <tr>
          <th
            class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer"
            @click="sort('fullName')"
          >
            <div
              class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7"
            >
              <SortArrowDownIcon
                v-if="
                  data.currentSort === 'fullName' &&
                  data.currentSortDir === 'desc'
                "
                class="absolute w-4 h-4 left-0"
              />
              <SortArrowUpIcon
                v-if="
                  data.currentSort === 'fullName' &&
                  data.currentSortDir === 'asc'
                "
                class="absolute w-4 h-4 left-0"
              />
              <span :class="{ 'pl-2 lg:pl-0': data.currentSort === 'fullName' }"
                >Name</span
              >
            </div>
          </th>
          <th
            class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer"
            @click="sort('company')"
          >
            <div
              class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7"
            >
              <SortArrowDownIcon
                v-if="
                  data.currentSort === 'company' &&
                  data.currentSortDir === 'desc'
                "
                class="absolute w-4 h-4 left-0"
              />
              <SortArrowUpIcon
                v-if="
                  data.currentSort === 'company' &&
                  data.currentSortDir === 'asc'
                "
                class="absolute w-4 h-4 left-0"
              />
              <span :class="{ 'pl-2 lg:pl-0': data.currentSort === 'company' }"
                >Company</span
              >
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div
              class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7"
            >
              <span>User Level</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div
              class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7"
            >
              <span>Edit</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div
              class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7"
            >
              <span>Delete</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody :class="{ 'animate-pulse': data.isLoading }">
        <template v-if="!data.isLoading">
          <tr
            v-for="(u, index) in usersListDataTable"
            :key="index"
            :class="{
              'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
            }"
          >
            <td
              class="
                text-xs
                md:text-sm
                lg:text-base
                text-black-1
                dark:text-dark-textPrimary
                font-medium
                p-2.5
                md:p-5
                lg:py-3.5 lg:px-7
                cursor-pointer
              "
              @click="
                this.$router.push({
                  name: 'UserDetail',
                  params: {
                    userId: u.id,
                  },
                })
              "
            >
              {{ u.fullName }}
            </td>
            <td
              class="
                text-xs
                md:text-sm
                lg:text-base
                text-black-1
                dark:text-dark-textPrimary
                font-medium
                p-2.5
                md:p-5
                lg:py-3.5 lg:px-7
              "
            >
              {{ u.company }}
            </td>
            <td
              class="
                text-xs
                md:text-sm
                lg:text-base
                text-black-1
                dark:text-dark-textPrimary
                font-medium
                p-2.5
                md:p-5
                lg:py-3.5 lg:px-7
              "
            >
              {{ u.accessLevel }}
            </td>
            <td
              class="
                text-xs
                md:text-sm
                lg:text-base
                text-magenta
                font-medium
                p-2.5
                md:p-5
                lg:py-3.5 lg:px-7
              "
            >
              <EditIcon
                class="h-5 w-5 md:h-7 md:w-7 cursor-pointer"
                @click="
                  this.$router.push({
                    name: 'UserDetail',
                    params: { userId: u.id },
                  })
                "
              />
            </td>
            <td
              class="
                text-xs
                md:text-sm
                lg:text-base
                text-magenta
                font-medium
                p-2.5
                md:p-5
                lg:py-3.5 lg:px-7
              "
            >
              <DeleteIcon
                class="h-5 w-5 md:h-7 md:w-7 cursor-pointer"
                @click="() => onDelete(u.id)"
              />
            </td>
          </tr>
        </template>

        <template v-if="data.isLoading">
          <tr
            v-for="n in 10"
            :key="n"
            :class="{
              'bg-grey-light dark:bg-dark-bgPrimary': n % 2 !== 0,
            }"
            class="h-14"
          >
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </tbody>
    </table>
    <div
      v-if="usersListDataTable.length"
      class="flex border-t-2 border-grey-light pt-4 lg:pt-7"
    >
      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(1)"
      >
        First
      </button>

      <div v-for="page in totalPages" :key="`pagination-${page}`">
        <span
          v-if="isDisplayPageNumber(page)"
          :class="
            page === data.currentPage
              ? 'border-blue-light'
              : 'border-grey-light dark:border-dark-bgPrimary'
          "
          class="
            flex
            justify-center
            items-center
            w-8
            md:w-10
            h-8
            md:h-10
            text-grey-mid
            dark:text-dark-textPrimary
            rounded-full
            border-2
            shadow-sm
            cursor-pointer
            mr-2
          "
          @click="paginate(page)"
          >{{ page }}</span
        >
      </div>

      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(totalPages)"
      >
        Last
      </button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount } from "vue";
import { useStore } from "vuex";

import { dynamicPaginate } from "@Helpers/datatable";
import { sleep } from "@Helpers/sleep";
import { deleteUser } from "@API/user/user-repository";
import { useTableData } from "@Libs/hooks/useDataTable";

import SortArrowUpIcon from "@Assets/images/SortArrowUpIcon";
import SortArrowDownIcon from "@Assets/images/SortArrowDownIcon";
import EditIcon from "@Assets/images/EditIcon";
import DeleteIcon from "@Assets/images/DeleteIcon";

export default {
  name: "LgScreenUsersListTable",
  components: {
    SortArrowUpIcon,
    SortArrowDownIcon,
    EditIcon,
    DeleteIcon,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      curerntFilterText: "",
      currentSort: "fullName",
      currentSortDir: "asc",
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      userList: [],
    });
    const sortStr = computed({
      get: () => {
        let s = "";
        if (data.currentSortDir === "desc") s += "-";
        return s + data.currentSort;
      },
      set: s => {
        //if s == current sort, reverse
        if (s === data.currentSort) {
          data.currentSortDir = data.currentSortDir === "asc" ? "desc" : "asc";
        } else data.currentSortDir = "asc";
        data.currentSort = s;
      },
    });
    const usersListDataTable = computed(() =>
      dynamicPaginate(data.userList, data.currentPage, data.perPage),
    );
    const totalPages = computed(() =>
      Math.ceil(data.userList.length / data.perPage),
    );

    onBeforeMount(getUsers);

    async function getUsers(isForce = true) {
      try {
        // Show loading
        if (isForce) {
          toggleLoading();
        } else {
          store.dispatch("toggleLoading");
        }
        // Access store's action
        const { fetchUsersListDatatable } = useTableData();

        data.userList = await fetchUsersListDatatable(
          data.curerntFilterText,
          sortStr.value,
        );
      } catch (error) {
        console.log({ error });
      } finally {
        // Hide Loading
        if (isForce) {
          toggleLoading(false);
        } else {
          store.dispatch("closeLoading");
        }
      }
    }

    async function sort(s) {
      sortStr.value = s;

      getUsers(false);
    }

    async function filter(f) {
      data.curerntFilterText = f;

      getUsers(false);
    }

    function paginate(p) {
      if (p === data.currentPage) return;

      // Set currentPage
      data.currentPage = p;
    }

    function toggleLoading() {
      data.isLoading = !data.isLoading;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    return {
      data,
      usersListDataTable,
      totalPages,
      getUsers,
      sort,
      filter,
      paginate,
      isDisplayPageNumber,
    };
  },
  beforeUnmount() {
    this.emitter.all.delete("submit-confirm-dialog");
    this.emitter.all.delete("header-input-change");
  },
  mounted() {
    // Listen header input change text
    this.emitter.on("header-input-change", searchText => {
      if (this.$route.name !== "UserList") return;
      this.$store.commit("SET_USER_SEARCH_TEXT", searchText);
      this.filter(searchText);
    });

    // Listen submit confirm dialog
    this.emitter.on("submit-confirm-dialog", async () => {
      try {
        this.$store.dispatch("toggleLoading");
        if (
          this.$store.state.auth.confirmDialog.confirmAction.type ===
          "DELETE_USER"
        ) {
          const result = await deleteUser(
            this.$store.state.auth.confirmDialog.confirmAction.payload,
          );

          // Show Alert
          this.$store.dispatch("toggleAlert", {
            isAlert: true,
            status: result.success,
            title: result.success ? "Successfully!" : "Failed!",
            message: result.message,
          });

          this.$store.dispatch("resetConfirmDialog");
        }
      } catch (error) {
        console.error({ error });
      } finally {
        this.$store.dispatch("closeLoading");
        this.getUsers();
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    });
  },
  methods: {
    onDelete(userId) {
      // Show confirm dialog
      this.$store.dispatch("toggleConfirmDialog", {
        isConfirmDialog: true,
        title: "Delete user",
        message:
          "Are you sure you want to delete selected user? All of your data will be permanently removed. This action cannot be undone.",
        confirmButtonText: "Delete",
        confirmAction: {
          type: "DELETE_USER",
          payload: userId,
        },
      });
    },
  },
};
</script>
