<template>
  <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5" @submit="onSubmit">
    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Name: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.name"
          name="name"
          type="text"
          :class="{
            'text-danger border border-danger': errors['name'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["name"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Title page: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.title"
          name="title"
          type="text"
          :class="{
            'text-danger border border-danger': errors['title'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["title"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Date: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <div class="inline-flex w-full">
          <div class="w-20 md:w-1/2 lg:w-1/3">
            <Field
              v-model="dateTime.date"
              name="eventDate"
              type="date"
              :class="{
                'text-danger border border-danger': errors['eventDate'],
              }"
              class="appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary text-center md:text-left border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3 mr-4 md:mr-12"
            />
            <p class="text-danger dark:text-danger text-base italic mr-1 md:mr-14 lg:mr-28">
              {{ errors["eventDate"] }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Home Name: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.homeName"
          name="homeName"
          type="text"
          :class="{
            'text-danger border border-danger': errors['homeName'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["homeName"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-full mt-2 md:mt-0 md:w-40 lg:w-1/4 md:pt-0">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Home Logo: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
        <label
          class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
        >
          <template v-if="empty(eventImage.homeLogo)">
            <XLogo class="h-8 w-8 text-blue-light absolute inset-y-1/4 left-5 lg:left-1/8" />
            <span class="font-bold absolute top-1/5 left-14 lg:left-1/4">
              <p>Logo</p>
            </span>
          </template>
          <img v-else :src="eventImage.homeLogo" alt="Home Logo" class="h-full" />
          <input name="homeLogo" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
        </label>

        <div class="flex" v-if="!empty(eventImage.homeLogo)">
          <button
            type="button"
            class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
            @click="handleDeleteFilesUpload('homeLogo')"
          >
            <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Live Cast Date Time: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Datepicker
          v-model="timePicker2"
          type="datetime"
          format="MM/dd/yyyy HH:mm"
          class="w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border focus:border-none focus:outline-none focus:ring-1 focus:ring-magenta rounded py-2 md:py-3"
          :dark="theme === 'dark'"
        />
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Welcome Message: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.welcomeMessage"
          as="textarea"
          name="welcomeMessage"
          rows="4"
          :class="{
            'text-danger border border-danger': errors['welcomeMessage'],
          }"
          class="w-full text-xs md:text-base text-black-1 dark:text-dark-textPrimary bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded p-2 md:p-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["welcomeMessage"] }}
        </p>
      </div>
    </div>

    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Using password: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="settings.isProtect" class="mb-2 self-start" />
        <template v-if="settings.isProtect">
          <Field
            v-model="settings.password"
            type="text"
            name="password"
            rows="5"
            :class="{
              'text-danger border border-danger': errors['password'],
            }"
            class="w-full text-xs md:text-base text-black-1 dark:text-dark-textPrimary bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded p-2 md:p-3"
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["password"] }}
          </p>
        </template>
      </div>
    </div>

    <button type="submit" class="button_large-Alternative absolute right-0 -bottom-4 md:-top-24 md:bottom-auto uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none">
      Save
    </button>
  </Form>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

import { getMomentT, formatDate, formatDateTime } from "@Helpers/date";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from "moment";
import axios from "axios";

import { sleep } from "@Helpers/sleep";
import { putEvent, getS3UploadKey } from "@API/event/event-repository";
import Switch from "@/components/layouts/Switch.vue";
import { get, forOwn, startCase, camelCase, keys } from "lodash";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { empty, isFile } from "@/utils";
import XLogo from "@Assets/images/XLogo";
import DeleteIcon from "@Assets/images/DeleteIcon";

export default {
  name: "GeneralTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    Switch,
    Datepicker,
    XLogo,
    DeleteIcon,
  },
  emits: ["toggleLoading", "onUpdate"],
  setup(props) {
    const currentEvent = computed(() => props.eventDetail);

    const settings = reactive({
      isProtect: !!get(currentEvent.value, "settings.isProtect"),
      password: get(currentEvent.value, "settings.password", ""),
    });
    const dateTime = computed(() => ({
      date: formatDate(currentEvent.value.dateTime, "yyyy-MM-DD"),
    }));

    const schema = Yup.object().shape({
      name: Yup.string().required("Event name is required"),
      eventDate: Yup.string().required("Event date is required"),
      password: Yup.lazy(() => (settings.isProtect ? Yup.string().required("password is required") : Yup.string())),
      // virtualEventUrl: Yup.object().shape({
      //   url: Yup.string()
      //     .matches(
      //       /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //       "Enter correct url",
      //     )
      //     .required("Virtual Event Url is required"),
      // }),
      /*      fundraisingSiteUrl: Yup.object().shape({
        // name: Yup.string().required("Fundraising Site Name is required"),
        url: Yup.string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter correct url"
          )
          .required("Fundraising Site Url is required"),
      }),
*/
    });
    const eventUrl = process.env.VUE_APP_URL;

    return {
      currentEvent,
      dateTime,
      schema,
      settings,
      eventUrl,
    };
  },

  data() {
    return {
      filesUpload: {
        homeLogo: null,
      },
      eventImage: {},
      payload: {},
      timePicker: "",
      timePicker2: null,
    };
  },
  watch: {
    currentEvent: {
      handler(event) {
        this.eventImage = {
          homeLogo: event.homeLogo,
        };
        this.payload = {
          homeLogo: event.homeLogo,
        };
        this.timePicker2 = event.liveCastDateTime;
        this.timePicker = !empty(event.liveCastDateTime) ? formatDateTime(event.liveCastDateTime) : null;
      },
      immediate: true,
    },
    timePicker2(value) {
      this.timePicker = formatDateTime(value);
    },
  },
  methods: {
    empty,
    handleDeleteFilesUpload(property, index) {
      let logos = "";
      if (property === "deceasedGallery") {
        logos = this.eventImage.deceasedGallery;
        logos.splice(index, 1);
        this.payload.deceasedGallery.splice(index, 1);
        this.filesUpload.deceasedGallery.splice(index, 1);
      } else {
        this.filesUpload = {
          ...this.filesUpload,
          [property]: null,
        };
        this.payload = {
          ...this.payload,
          [property]: logos,
        };
      }
      this.eventImage = {
        ...this.eventImage,
        [property]: logos,
      };
    },
    handleChangeFilesUpload(e, index) {
      const fileSelected = e.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        if (e.target.name !== "deceasedGallery") {
          this.filesUpload[e.target.name] = fileSelected;
          this.eventImage = {
            ...this.eventImage,
            [e.target.name]: event.target.result,
          };
        } else {
          const logos = this.eventImage.deceasedGallery || [];
          if (index > -1) {
            logos[index] = event.target.result;
            this.filesUpload[e.target.name][index] = fileSelected;
            this.payload.deceasedGallery.splice(index, 1);
          } else {
            this.filesUpload[e.target.name].push(fileSelected);
            logos.push(event.target.result);
          }
          this.eventImage = {
            ...this.eventImage,
            [e.target.name]: logos,
          };
        }
      };
      reader.readAsDataURL(fileSelected);
    },
    async doUpdateFile(file, property) {
      if (!file.type.includes("image/")) return;

      const s3Result = await getS3UploadKey(file.type.replace("image/", ""));

      const {
        data: { fields, url },
      } = s3Result;

      const formData = new FormData();

      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", file);

      const result = await axios.post(url, formData, {});

      if (result.status === 204) {
        if (property === "deceasedGallery") {
          this.payload[property] = this.payload[property] || [];
          this.payload[property].push(fields.key);
        } else {
          this.payload[property] = fields.key;
        }
      }

      // Show Alert
      // Show Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: true,
        status: result.status === 204,
        title: `[${result.status === 204 ? "Successfully" : "Failed"}] ${startCase(camelCase(property))}`,
        message: result.status === 204 ? "Upload file successfully!" : "Upload file failed!",
      });

      await sleep(1600);

      // Hide Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: false,
      });
    },
    async handleUploadFiles(property) {
      // this.$emit("toggleLoading");
      if (property === "deceasedGallery") {
        const fileUploads = this.filesUpload[property];
        for (let i = 0; i < fileUploads.length; i++) {
          await this.doUpdateFile(fileUploads[i], property);
        }
      } else {
        await this.doUpdateFile(this.filesUpload[property], property);
      }
      // this.$emit("toggleLoading");
    },
    async onSubmit() {
      try {
        this.$emit("toggleLoading");
        const payload = {
          ...this.currentEvent,
          liveCastDateTime: getMomentT(this.timePicker).toISOString(),
          dateTime: getMomentT(this.dateTime.date).format(),
          settings: {
            ...this.currentEvent.settings,
            ...this.settings,
          },
        };
        forOwn(payload, (value, key) => {
          if (value === " " || value?.url === " ") delete payload[key];
          // if (key.includes("Url") && (!value || !value.url.length || value.url === " ")) delete payload[key];
        });
        const filesUploadKeys = keys(this.filesUpload);

        for (let i = 0; i < filesUploadKeys.length; i++) {
          const file = this.filesUpload[filesUploadKeys[i]];
          if (isFile(file) || !empty(file)) {
            await this.handleUploadFiles(filesUploadKeys[i]);
          }
        }
        const result = await putEvent({
          ...payload,
          ...this.payload,
        });
        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Successfully!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
