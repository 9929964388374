<template>
  <div
    class="
      flex
      fixed
      top-0
      left-0
      justify-center
      items-center
      w-full
      h-full
      bg-grey-dark
      opacity-75
      z-50
    "
  >
    <LoadingIcon />
  </div>
</template>
<script>
import LoadingIcon from "@Components/layouts/LoadingIcon";
export default {
  components: {
    LoadingIcon,
  },
};
</script>
