import { trim, some, isString, endsWith } from "lodash";
import { Base64 } from "js-base64";
export function empty(obj) {
  if (obj === undefined || obj === null) {
    return true;
  }
  return trim(obj) === "" || JSON.stringify(obj) === JSON.stringify({}) || JSON.stringify(obj) === JSON.stringify([]);
}

export function flattenObject(ob) {
  var toReturn = [];
  for (var i in ob) {
    // eslint-disable-next-line no-prototype-builtins
    if (!ob.hasOwnProperty(i)) continue;
    if (typeof ob[i] === "object") {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn.push(i + "." + flatObject[x]);
      }
    } else {
      toReturn.push(i);
    }
  }
  return toReturn;
}

export function insertJs(s, srcArrInput, cb = () => {}) {
  const srcArr = srcArrInput.filter(item => !empty(item));
  const d = window.document;
  const c = Array.prototype.slice.call(d.head.getElementsByTagName(s));

  if (srcArr.length === 0) {
    cb();
  } else {
    const src = srcArr[0];
    const id = getFileName(src);
    if (!some(c, item => item.id === id)) {
      const curTime = new Date().getTime();
      const js = d.createElement(s);
      const fjs = c[c.length - 1];
      js.id = id;
      if (s === "link") {
        js.href = src + "?ts=" + curTime;
        js.rel = "stylesheet";
      } else if (s === "script") {
        js.src = src + "?ts=" + curTime;
      }
      fjs.parentNode.insertBefore(js, fjs.nextSibling);
      js.onload = () => {
        srcArrInput.shift();
        insertJs(s, srcArrInput, cb);
      };
    } else {
      srcArrInput.shift();
      insertJs(s, srcArrInput, cb);
    }
  }
}
export function getFileName(path) {
  var fileName = "";
  // eslint-disable-next-line no-useless-escape
  var patt = /\/([^\\\/:*\"<>|]+)$/g;
  var n = patt.exec(path);
  if (n !== null) {
    fileName = n[1];
  }
  return fileName;
}

export function convertMsToTime(duration) {
  // var milliseconds = Math.floor((duration % 1000) / 100),
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  // return hours + "h:" + minutes + "m:" + seconds + "s." + milliseconds;
  return hours + ":" + minutes + ":" + seconds;
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export function isImageFile(fileName) {
  return isString(fileName) && some([".jpg", ".jpeg", ".png"], item => endsWith(fileName, item));
}

export function isFile(input) {
  return ("File" in window && input instanceof File) || ("Blob" in window && input instanceof Blob);
}

export function convertUrlToLink(text) {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

  return text.replace(
    urlRegex,
    '<a href="$1" target="_blank" rel="noopener noreferrer" style="color:#007eb9">$1 <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0z"  fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg></a>',
  );
}

function isObjectOrProxy(obj) {
  if (obj === null || obj === undefined || typeof obj !== "object") {
    return false;
  }
  if (Object.prototype.toString.call(obj) !== "[object Object]" && Object.prototype.toString.call(obj) !== "[object Window]" && Object.prototype.toString.call(obj) !== "[object ProxyObject]") {
    return false;
  }
  return true;
}

function isEncodedBase64(encoded1) {
  const decoded1 = Buffer.from(encoded1, "base64").toString("utf8");
  const encoded2 = Buffer.from(decoded1, "binary").toString("base64");
  return encoded1 === encoded2;
}

export function decodeBase64(value, isJson = false) {
  try {
    if (isEncodedBase64(value)) {
      const textPlain = Base64.decode(value);
      if (isJson) {
        return JSON.parse(textPlain);
      }
      return textPlain;
    }
  } catch (err) {
    console.error("decodeBase64: ", err);
  }
  return value;
}

export function encodeBase64(value) {
  let text = value;
  if (isObjectOrProxy(value)) {
    text = JSON.stringify(value);
  }
  return Base64.encode(text);
}
