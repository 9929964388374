import { isLoggedIn, getUserInfo } from "@Libs/hooks/useAuth";
let userInfo = {
  username: "",
  email: "",
  id: "",
  role: "User",
};
if (isLoggedIn()) {
  userInfo = getUserInfo();
}
export default {
  state: {
    isLoading: false,
    alert: {
      isAlert: false,
      status: true,
      title: "",
      message: "",
    },
    confirmDialog: {
      isConfirmDialog: false,
      component: null,
      title: "",
      message: "",
      confirmButtonText: "",
      confirmAction: {
        type: "",
        payload: null,
      },
      customCSS: {},
    },
    ...userInfo,
  },
  mutations: {
    TOGGLE_LOADING(state) {
      state.isLoading = true;
    },
    CLOSE_LOADING(state) {
      state.isLoading = false;
    },
    TOGGLE_CONFIRM_DIALOG(state, confirmation) {
      state.confirmDialog = {
        ...state.confirmDialog,
        ...confirmation,
      };
    },
    RESET_CONFIRM_DIALOG(state) {
      state.confirmDialog = {
        isConfirmDialog: false,
        title: "",
        message: "",
        component: null,
        confirmButtonText: "",
        confirmAction: {
          type: "",
          payload: null,
        },
        customCSS: {},
      };
    },
    SET_ALERT(state, alert) {
      state.alert = {
        ...state.alert,
        ...alert,
      };
    },
    SET_USER_INFO(state, userInfo) {
      state.username = userInfo.username;
      state.email = userInfo.email;
      state.id = userInfo.id;
      state.role = userInfo.role;
    },
    DELETE_USER_INFO(state) {
      state.username = "";
      state.email = "";
      state.id = "";
      state.role = "User";
    },
  },
  actions: {
    toggleLoading({ commit }) {
      commit("TOGGLE_LOADING");
    },
    closeLoading({ commit }) {
      commit("CLOSE_LOADING");
    },
    toggleConfirmDialog({ commit }, payload) {
      commit("TOGGLE_CONFIRM_DIALOG", payload);
    },
    resetConfirmDialog({ commit }) {
      commit("RESET_CONFIRM_DIALOG");
    },
    toggleAlert({ commit }, payload) {
      commit("SET_ALERT", payload);
    },
    saveUserInfo({ commit }, payload) {
      commit("SET_USER_INFO", payload);
    },
    deleteUserInfo({ commit }) {
      commit("DELETE_USER_INFO");
    },
  },
  getters: {
    isLoading: state => {
      return state.isLoading;
    },
    isConfirmDialog: state => {
      return state.confirmDialog.isConfirmDialog;
    },
    eventListenConfirmDialog: state => {
      return state.confirmDialog.eventListen;
    },
    customCSSConfirmDialog: state => {
      return state.confirmDialog.customCSS;
    },
    isAlert: state => {
      return state.alert.isAlert;
    },
    getUserInfo: state => {
      return {
        username: state.username,
        email: state.email,
        id: state.id,
        role: state.role,
      };
    },
    isAdmin: state => {
      return state.role === "Admin";
    },
  },
};
