<template>
  <div>
    <div class="block md:flex justify-start items-center">
      <button class="button_large uppercase bg-magenta border rounded-full py-2 px-4 focus:outline-none" @click="doCreateQuestion">New</button>
    </div>
    <Popup :questionDetail="data.questionDetail" :isNew="data.isNew" @onClose="data.questionDetail = {}" @onUpdate="doUpdateQuestion" />
    <table class="text-left w-full">
      <thead>
        <tr>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <span>Question</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <span>Answer</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer w-12">
            <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <span>Edit</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer w-12">
            <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <span>Remove</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody :class="{ 'animate-pulse': data.isLoading }">
        <template v-if="!data.isLoading">
          <tr
            v-for="(u, index) in questionListDataTable"
            :key="index"
            :class="{
              'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
            }"
          >
            <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              {{ u.question }}
            </td>
            <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              {{ u.answer }}
            </td>
            <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <EditIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="() => onEdit(u, index)" />
            </td>
            <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <DeleteIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="() => onDelete(index)" />
            </td>
          </tr>
        </template>

        <template v-if="data.isLoading">
          <tr
            v-for="n in 10"
            :key="n"
            :class="{
              'bg-grey-light dark:bg-dark-bgPrimary': n % 2 !== 0,
            }"
            class="h-14"
          >
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="questionListDataTable.length > 1" class="flex border-t-2 border-grey-light pt-4 lg:pt-7">
      <button
        class="w-16 md:w-20 h-8 md:h-10 text-xs md:text-base uppercase font-bold text-grey-mid dark:text-dark-textPrimary bg-white dark:bg-dark-bgSecondary border-2 border-grey-light dark:border-dark-bgPrimary rounded-full mr-2 focus:outline-none"
        @click.prevent="paginate(1)"
      >
        First
      </button>

      <div v-for="page in totalPages" :key="`pagination-${page}`">
        <span
          v-if="isDisplayPageNumber(page)"
          :class="page === data.currentPage ? 'border-blue-light' : 'border-grey-light dark:border-dark-bgPrimary'"
          class="flex justify-center items-center w-8 md:w-10 h-8 md:h-10 text-grey-mid dark:text-dark-textPrimary rounded-full border-2 shadow-sm cursor-pointer mr-2"
          @click="paginate(page)"
          >{{ page }}</span
        >
      </div>

      <button
        class="w-16 md:w-20 h-8 md:h-10 text-xs md:text-base uppercase font-bold text-grey-mid dark:text-dark-textPrimary bg-white dark:bg-dark-bgSecondary border-2 border-grey-light dark:border-dark-bgPrimary rounded-full mr-2 focus:outline-none"
        @click.prevent="paginate(totalPages)"
      >
        Last
      </button>
    </div>

    <button
      @click.prevent="onSubmit"
      class="button_large-Alternative absolute right-0 -bottom-4 md:-top-24 md:bottom-auto uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
    >
      Save
    </button>
  </div>
</template>

<script>
import { computed, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import { dynamicPaginate } from "@Helpers/datatable";
import { sleep } from "@Helpers/sleep";
import { putEvent } from "@API/event/event-repository";
import { map, omit } from "lodash";

import DeleteIcon from "@Assets/images/DeleteIcon";
import EditIcon from "@Assets/images/EditIcon";
import Popup from "./Popup";

// import Create from "./Create";

export default {
  name: "QA",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    DeleteIcon,
    EditIcon,
    Popup,
    // Create,
  },
  emits: ["onUpdate", "toggleLoading"],
  setup(props) {
    const data = reactive({
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      isNew: false,
      isEditPoll: false,
      questionList: [],
      questionDetail: {},
    });

    const questionListDataTable = computed(() => dynamicPaginate(data.questionList, data.currentPage, data.perPage));
    const totalPages = computed(() => Math.ceil(data.questionList.length / data.perPage));

    onMounted(() => {
      data.questionList = props.eventDetail.questionAnswers || [];
    });
    function paginate(p) {
      if (p === data.currentPage) return;

      // Set currentPage
      data.currentPage = p;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    function doCreateQuestion() {
      data.isNew = true;
      data.questionDetail = {
        question: "",
        answer: "",
        ind: data.questionList.length,
      };
    }

    function doUpdateQuestion(question) {
      if (data.isNew) {
        data.questionList.push({
          ...question,
        });
      } else {
        data.questionList.splice(question.ind, 1, {
          ...question,
        });
      }
      data.questionDetail = {};
    }
    function onEdit(question, ind) {
      data.isNew = false;
      data.questionDetail = {
        ...question,
        ind,
      };
    }

    return {
      data,
      questionListDataTable,
      totalPages,
      paginate,
      isDisplayPageNumber,
      doCreateQuestion,
      doUpdateQuestion,
      onEdit,
    };
  },
  mounted() {
    // Listen submit confirm dialog
    this.emitter.on("submit-confirm-dialog-question", async () => {
      try {
        const { type, payload } = this.$store.state.auth.confirmDialog.confirmAction;
        if (type === "DELETE_QUESTION") {
          this.data.questionList.splice(payload, 1);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.$store.dispatch("resetConfirmDialog");
      }
    });
  },
  methods: {
    onDelete(questionIndex) {
      // Show confirm dialog
      this.$store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-question",
        isConfirmDialog: true,
        title: "Delete Question",
        message: "Are you sure you want to remove this question?",
        confirmButtonText: "Delete",
        confirmAction: {
          type: "DELETE_QUESTION",
          payload: questionIndex,
        },
      });
    },
    async onSubmit() {
      try {
        this.$emit("toggleLoading");
        const payload = {
          ...this.eventDetail,
          questionAnswers: map(this.data.questionList, item => omit(item, ["ind"])),
        };

        const result = await putEvent(payload);
        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Successfully!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
